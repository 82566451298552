<template>
  <div class="a-detail">
    <div class="top-c">
      <div><span class="title">{{appraisalData ? appraisalData.title : ''}}</span><span class="note">{{appraisalData ? appraisalData.statusText : ''}}</span></div>
      <div class="remark">{{appraisalData ? appraisalData.remark : ''}}</div>
      <div class="remark" v-if="chooseDataDetail">{{chooseDataDetail.userName}}</div>
    </div>
    <div class="bottom-c-user" v-if="userType.includes('check') && !questioning">
      <div class="user-item" :class="{'user-item-wap': clientType === 'wap'}" v-for="(item, index) in showBkhList" :key="item.id" @click="next(index)">
        <div class="title">
          <div>{{item.userName}}</div>
        </div>
        <div class="status">
          <div>{{(mxList2.find(v => v.beAppraisalGroupDetailId === item.id) || {isOk: false}).isOk ? '已完成' : '未完成'}}</div>
        </div>
        <div class="by"></div> 
        <div class="by-x"></div> 
      </div>
    </div>
    <div class="bottom-c" v-if="userType.includes('check') && questioning">
      <div class="q-item" v-for="item in qs" :key="item.id">
        <div class="title">
          {{item.title}}
        </div>
        <div class="check-box" v-if="appraisalRule.type === '选择制'">
          <fm-radio-group v-model="item.choose">
            <fm-radio :value="itemO" :label="itemO" v-for="itemO in ops" :key="itemO"></fm-radio>
          </fm-radio-group>
        </div>
        <div class="check-box" v-if="appraisalRule.type === '分数制'">
          <fm-input-new @enten="enten" @blur="onBlur" @focus="focus($event)" :placeholder="appraisalRule.min + '-' + appraisalRule.max" v-model="item.choose">
          </fm-input-new>
        </div>
      </div>
      <div class="btn-s"><fm-btn @click="save(chooseIndex - 1)">上一位</fm-btn><fm-btn @click="save(chooseIndex + 1)">下一位</fm-btn><fm-btn @click="save(null)">关闭</fm-btn></div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

import {
  appraisalRequest as request,
  appraisalGroupDetailRequest,
  appraisalQuestionRequest,
  appraisalResultDetailRequest
} from '../../api'

export default {
  data () {
    let statusMap = {}
    store.getters.appraisalGroupDetailStatusList.forEach((v) => {
      statusMap[v.key] = []
    })
    return {
      appraisalRule: {
        type: '选择制',
        ops: '优良中差',
        max: '100',
        min: '0',
        pre: '整数'
      },
      chooseIndex: 0,
      questioning: false,
      qs: [],
      userType: [],
      mxList2: [],
      appraisalId: null,
      mxList: [],
      bkhList: statusMap,
      appraisalGroupDetailId: null,
      appraisalData: null,
      chooseDataDetail: null
    }
  },
  computed: {
    workerId () {
      return this.$store.getters.currentWorkerId
    },
    ops () {
      let data = []
      if (this.appraisalData && this.appraisalData.rule) {
        data = this.appraisalRule.ops.split('')
      }
      return data
    },
    clientType () {
      return this.$store.getters.clientType
    },
    showBkhList () {
      let data = []
      let keys = ['doing', 'ready', 'wait', 'over', 'giveup']
      keys.forEach(v => data = data.concat(this.bkhList[v]))
      return data
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    appraisalGroupDetailStatusList () {
      return this.$store.getters.appraisalGroupDetailStatusList
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next(vm => vm.$nextTick(vm.init))
    } else {
      next(from)
    }
  },
  methods: {
    async init () {
      this.appraisalId = this.$router.history.current.query.id
      await this.loadData()
      if (this.appraisalData === null) {
        return
      }
      await this.loadUserType()
      if (this.userType.includes('check')) {
        this.loadQs()
        this.loadDetailList()
        this.loadMxList2()
      } else {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核权限'
        })
      }
    },
    onBlur (value) {
      let isOk = true
      if (this.appraisalRule.type === '分数制') {
        if (isNaN(value) || Number(value) < Number(this.appraisalRule.min) || Number(value) > Number(this.appraisalRule.max)) {
          isOk = false
        }
        let index = this.appraisalRule.pre === '整数' ? 0 : (this.appraisalRule.pre === '两位小数' ? 2 : 1)
        if (isOk && String(value).includes('.')) {
          isOk = String(value).split('.')[1].length < (index + 1)
        }
      }
      if (!isOk) {
        this.$notice.success({
          title: '系统提示',
          desc: '请输入合理分值'
        })
      }
    },
    enten () {},
    focus (event) {
      event.currentTarget.select()
    },
    async save (index) {
      let isOk = true
      let values = this.qs.map((v) => {
        if (isOk && this.appraisalRule.type === '分数制') {
          if (isNaN(v.choose) || Number(v.choose) < Number(this.appraisalRule.min) || Number(v.choose) > Number(this.appraisalRule.max)) {
            isOk = false
          }
          let index = this.appraisalRule.pre === '整数' ? 0 : (this.appraisalRule.pre === '两位小数' ? 2 : 1)
          if (isOk && String(v.choose).includes('.')) {
            isOk = String(v.choose).split('.')[1].length < (index + 1)
          }
        }
        return {
          id: v.id,
          value: v.choose
        }
      })
      if (!isOk) {
        this.$notice.success({
          title: '系统提示',
          desc: '请输入合理分值'
        })
        return
      }
      if (values.filter(v => v.value !== null && v.value !== '').length > 0) {
        let parm = {
          appraisalId: this.appraisalId,
          beAppraisalGroupDetailId: this.chooseDataDetail.id,
          appraisalGroupDetailId: this.appraisalGroupDetailId,
          value: JSON.stringify(values)
        }
        if (this.mxList && this.mxList.length > 0) {
          if (this.mxList[0].value !== parm.value) {
            await appraisalResultDetailRequest.update(this.mxList[0].id, parm)
            this.$notice.success({
              title: '系统提示',
              desc: '完成'
            })
          }
          this.loadMxList2()
        } else {
          await appraisalResultDetailRequest.add(parm)
          this.$notice.success({
            title: '系统提示',
            desc: '完成'
          })
          this.loadMxList2()
        }
      }
      if (index !== null) {
        this.next(index)
      } else {
        this.chooseIndex = 0
        this.chooseDataDetail = null
        this.questioning = false
      }
    },
    async next (index) {
      this.mxList = []
      if (index < 0) {
        this.$notice.success({
          title: '系统提示',
          desc: '没有上一个了'
        })
        return
      }
      if (index > this.showBkhList.length - 1) {
        this.$notice.success({
          title: '系统提示',
          desc: '没有下一个了'
        })
        return
      }
      this.$el.scrollTop = 0
      this.chooseIndex = index
      this.chooseDataDetail = this.showBkhList[this.chooseIndex]
      this.questioning = true
      await this.loadMxList()
      if (this.mxList && this.mxList.length > 0) {
        let r = this.mxList[0].value ? JSON.parse(this.mxList[0].value) : []
        this.qs.forEach(v => v.choose = (r.find(v1 => v1.id === v.id) || {value: null}).value)
      } else {
        this.qs.forEach(v => v.choose = null)
      }
    },
    async loadQs () {
      this.qs = []
      let qs = await appraisalQuestionRequest.get({
        appraisalId: this.appraisalId
      })
      qs.forEach(v => v.choose = null)
      qs.sort((a, b) => a.orderNum - b.orderNum)
      this.qs = qs
    },
    async loadMxList () {
      let parm = {
        appraisalGroupDetailId: this.appraisalGroupDetailId,
        beAppraisalGroupDetailId: this.chooseDataDetail.id
      }
      this.mxList = await appraisalResultDetailRequest.get(parm)
    },
    async loadMxList2 () {
      let parm = {
        appraisalGroupDetailId: this.appraisalGroupDetailId
      }
      this.mxList2 = await appraisalResultDetailRequest.get(parm)
      this.mxList2.forEach((v) => {
        v.values = v.value ? JSON.parse(v.value) : []
        v.isOk = v.values.filter(v => v.value != null).length === this.qs.length
      })
    },
    async loadUserType () {
      let parm = {
        appraisalId: this.appraisalId,
        workerId: this.workerId ? undefined : -1
      }
      let data = await appraisalGroupDetailRequest.getMy(parm)
      let userType = []
      data.forEach((v) => {
        if (!userType.includes(v.appraisalGroupType)) {
          userType.push(v.appraisalGroupType)
        }
        if (v.appraisalGroupType === 'check') {
          this.appraisalGroupDetailId = v.id
        }
      })
      this.userType = userType
    },
    async loadData () {
      this.loading = true
      this.appraisalData = null
      let parm = {id: this.appraisalId}
      let data = await request.get(parm)
      data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
      if (data.length == 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '考核不存在'
        })
      } else {
        let dataItem = data[0]
        if (dataItem.doing !== 'doing') {
          this.$notice.info({
            title: '系统提示',
            desc: '考核未开始或已结束'
          })
        } else {
          this.appraisalData = data[0]
          this.appraisalRule = JSON.parse(this.appraisalData.rule)
        }
      }
      this.loading = false
    },
    async loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      let mxList = await appraisalResultDetailRequest.get({appraisalGroupDetailId: this.appraisalGroupDetailId})
      let mxMap = {}
      mxList.forEach((v) => mxMap[v.beAppraisalGroupDetailId] = v)
      appraisalGroupDetailRequest.get(parm).then((data) => {
        Object.keys(this.bkhList).forEach(v => this.bkhList[v] = [])
        data.sort((a, b) => (a.orderNum || 1000) - (b.orderNum || 1000))
        data.forEach((v) => {
          v.checkData = mxMap[v.id] || null
          v.df = v.checkData ? v.checkData.value : null
          v.statusText = (this.appraisalGroupDetailStatusList.find(v1 => v1.key === v.status) || {label: ''}).label
          this.bkhList[v.status].push(v)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btn-s {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
}
.a-detail {
  overflow: auto;
  .fm-input-new {
    width: 142px !important;
    height: 32px;
  }
  .bkhr-btns {
    .fm-btn-norm {
      height: 32px !important;
      width: 96px !important;
      border-radius: 16px !important;
    }
  }
}
</style>


<style lang="less" scoped>
.user-item {
  background: #fff;
  position: relative;
  width: 280px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 16px;
  .by {
    position: absolute;
    left: -3px;
    top: -16px;
    width: 100px;
    height: 50px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    background: #fff7f9;
  }
  .by-x {
    position: absolute;
    right: -16px;
    bottom: -16px;
    width: 100px;
    height: 50px;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    background: #fff7f9;
  }
  .title {
    font-size: 20px;
    color: #707070;
  }
  .status {
    color: #F4628F;
    width: 96px;
    height: 32px;;
    display: flex;
    justify-content: center;
    border: 1px solid #FB6D8F;
    border-radius: 16px;
    align-items: center;
    margin-top: 20px;
  }
}
.user-item-wap {
  width: calc(100% - 32px);
}
.q-item {
  background: #fff;
  margin: 14px 14px 0;
  display: flex;
  justify-content: space-between;
  padding: 28px;
}
.br-t {
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  display: flex;
  div {
    margin: 9px 9px 0 2px;
    transform: rotate(180deg);
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: rgba(0,0,0,0.65) !important;
    }
  }
}
.show-all-btn {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  div {
    line-height: 22px;
    color: #00C8C4;
    font-size: 14px;
  }
}
.top-c {
  background: #fff;
  padding: 14px 28px;
  .title {
    line-height: 22px;
    font-size: 16px;
    color: #333145;
  }
  .note {
    color: #00C8C4;
  }
  .remark {
    color: rgba(0,0,0,0.45);
    font-size: 14px;
    line-height: 23px;
    padding-top: 14px;
  }
}
.not-text {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
  padding: 0 2rem 4rem;
}
.bkhr-list-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item {
  position: relative;
  padding: 16px 20px;
  margin: 8px;
  line-height: 2rem;
  background: #FDFDFD;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  font-size: 14px;
  .yy-fz {
    font-size: 32px;
    line-height: 45px;
    position: absolute;
    top: 0;
    right: 22px;
  }
  .bkhr-btns {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
.b-c-t {
  display: flex;
  flex: 1;
}
.b-r {
  flex: 1;
  margin: 8px;
  background: #fff;
}
.b-l {
  flex: 1;
}
.a-detail {
  display: flex;
  flex-direction: column;
  background: #fff7f9;
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
}
.group-item {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin: 1rem;
  border: 1px solid #eee;
}
.bottom-c-user {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.bottom-c {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: #777;
  margin: 1rem 2rem;
  line-height: 2;
}
</style>